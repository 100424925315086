<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="mr-3">List Data Pembayaran Pajak</strong>
        <!-- <a-button
            type="button"
            class="btn btn-outline-primary ml-2"
            @click="connector('nonanggota')"
          >
            Tambah Non Nasabah
          </a-button> -->
        <a-date-picker
          class="mr-2"
          style="width: 130px;"
          v-model="tgl1"
          :allowClear="true"
          format="DD-MM-YYYY"
          @change="rangedofunction"
        />
        <a-button-group>
          <a-button class="btn btn-outline-success" @click="getAllData(true)"
            >Refresh Data</a-button
          >
          <a-button
            type="button"
            class="btn btn-outline-primary"
            @click="showModal('create', {})"
          >
            Tambah
          </a-button>
        </a-button-group>
        <strong class="pull-right" :key="componentKey">Total Pembayaran Hari ini: {{ currencyFormatter(totalpembayaranhariini) }}</strong>
        <!-- <a-button class="btn btn-outline-success float-right" @click="exportExcel"
          >Export Data</a-button
        > -->
      </template>
      <a-table
        :columns="columns"
        :dataSource="data"
        style="margin: -25px"
        :scroll="{ x: 0, y: 300 }"
        :pagination="{
          hideOnSinglePage: true,
          defaultPageSize: 1000,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a
            href="javascript:void(0)"
            class="text-warning"
            @click="showModal('update', record)"
          >
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a
            href="javascript:void(0)"
            class="text-danger"
            @click="showDeleteConfirm(record)"
          >
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
              More actions <a-icon type="down" />
            </a> -->
        </span>
        <span slot="numberFormat" slot-scope="text">
          {{ currencyFormatter(text) }}
        </span>
        <span slot="created_at" slot-scope="text">
          {{ formatDate(text) }}
        </span>
      </a-table>
    </a-card>
    <a-modal
      v-model="formvisible"
      title="Form Pembayaran Pajak"
      :dialogStyle="{ top: '10px' }"
      :width="400"
      :footer="null"
    >
      <!-- <template slot="footer">
        <a-button class="" type="danger" @click="handleCancel">Cancel</a-button>
        <a-button class="" type="primary" @click="handleSubmit">Submit</a-button>
      </template> -->
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-input
            class="customInput1"
            v-model="modalInput.no_pembayaran"
            style="text-align: center"
            placeholder="Input Nomor Pembayaran"
          ></a-input>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <a-button
            type="button"
            class="btn btn-outline-secondary pull-right"
            @click="getTagihan"
          >
            Cek Tagihan
          </a-button>
        </div>
        <template v-if="detailvisible">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
            <strong class="" style="">Nominal Tagihan Pajak Daerah</strong>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
            <a-input
                :readOnly="true"
              class="customInput"
              v-model="modalInput.va_name"
              style="text-align: center; width: 90%"
              placeholder="Nama"
            ></a-input>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
            <a-input
                :readOnly="true"
              class="customInput"
              v-model="modalInput.objekPajak"
              style="text-align: center; width: 90%"
              placeholder="Objek Pajak"
            ></a-input>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
            <a-input
                :readOnly="true"
              class="customInput"
              v-model="modalInput.masaPajak"
              style="text-align: center; width: 90%"
              placeholder="Masa Pajak"
            ></a-input>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
            <cleave
                :readOnly="true"
              :options="{ numeral: true }"
              class="ant-input customInput"
              v-model="modalInput.nilaiPajak"
              :raw="true"
              style="text-align: center; width: 90%"
              placeholder="Nominal"
            ></cleave>
          </div>
          <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11 mt-1">
            <a-button
              type="button"
              class="btn btn-outline-secondary pull-right"
              @click="bayarTagihan"
            >
              Bayar Tunai
            </a-button>
          </div>
        </template>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  //   {
  //     title: 'Action',
  //     scopedSlots: { customRender: 'action' },
  //     fixed: 'left',
  //     width: 70,
  //   },
  {
    title: 'Kode Bayar',
    dataIndex: 'no_pembayaran',
    width: 90,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.no_pembayaran.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
    width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.keterangan.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Nominal',
    dataIndex: 'jumlah',
    width: 80,
    // fixed: 'left',
    align: 'right',
    scopedSlots: {
      customRender: 'numberFormat',
    },
    sorter: (a, b) => a.jumlah - b.jumlah,
  },
  {
    title: 'Date Time',
    dataIndex: 'created_at',
    width: 90,
    // fixed: 'left',
    scopedSlots: {
      customRender: 'created_at',
    },
  },
  {
    title: 'Username',
    dataIndex: 'username',
    width: 90,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.username.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    cleave: Cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created() {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      totalpembayaranhariini: 0,
      searchText: '',
      tgl1: null,
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,

      // Modal property
      masterRekening: [],
      action: null,
      formvisible: false,
      editdata: {},
      detailvisible: true,
      detailTagihan: {},
      modalInput: {
        no_pembayaran: '',
        description: '',
        va_name: '',
        objekPajak: '',
        masaPajak: '',
        nilaiPajak: '',
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    // localStorage.removeItem('minibankedittabungandata')
    // this.tgl1 = date
    this.datenow = date.toString()
    // this.getAgamaF()
  },
  methods: {
    moment,
    totalJumlah() {
      this.totalpembayaranhariini = this.data.reduce((total, item) => {
        // Konversi jumlah ke integer dan tambahkan ke total
        return total + parseInt(item.jumlah || 0)
      }, 0)
      this.componentKey += 1
    },
    rangedofunction(date, dateString) {
      this.tgl1 = date === null ? null : date.format('YYYY-MM-DD')
      this.getAllData(true)
    },
    async bayarTagihan() {
      var fd = {
        no_pembayaran: this.modalInput.no_pembayaran,
      }
      var res = await lou.customUrlPost2('bayar_tagihan', fd)
      if (res) {
        this.handleCancel()
      }
    },
    async getTagihan() {
      var res = await lou.customUrlGet3(
        'tagihan?no_pembayaran=' + this.modalInput.no_pembayaran, false, false, '', true, false,
      )
      //   console.log('res', res)
      if (res) {
        this.detailvisible = true
        this.detailTagihan = res.data
        this.modalInput.description = this.detailTagihan.description
        this.modalInput.va_name = this.detailTagihan.va_name
        this.modalInput.objekPajak = this.detailTagihan.jenisPajak + ' - ' + this.detailTagihan.objekPajak
        this.modalInput.masaPajak = this.detailTagihan.masaPajak
        this.modalInput.nilaiPajak = this.detailTagihan.nilaiPajak
      }
    },
    async showModal(action, data) {
      this.masterRekening = []
      // var res = await lou.customUrlGet2('general/master/rekening')
      this.formvisible = true
      this.action = action
      if (action === 'update') {
        this.modalInput = this.$g.clone(data)
        this.editdata = data
      } else {
        this.modalInput = {
          no_pembayaran: '',
          description: '',
          va_name: '',
          objekPajak: '',
          masaPajak: '',
          nilaiPajak: '',
        }
      }
      // if (res) {
      //   res.data.forEach((x) => {
      //     var np = {
      //       ...x,
      //       label: x.kode + ' - ' + x.keterangan,
      //     }
      //     this.masterRekening.push(np)
      //   })
      //   this.formvisible = true
      //   this.action = action
      //   // console.log('this.masterRekening', this.masterRekening)
      //   if (action === 'update') {
      //     this.modalInput = this.$g.clone(data)
      //     this.editdata = data
      //   }
      // } else {
      //   // lou.shownotif('Api!', 'Pastikan i')
      // }
    },
    async getAllData(onclick = false) {
      var imbuhan = ''
      imbuhan += this.tgl1 !== null ? '?tgl1=' + this.tgl1 : ''
      var response = await lou.customUrlGet2('list_tagihan' + imbuhan)
      if (response) {
        this.data = response.data
        this.totalJumlah()
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value, 'YYYY-MM-DDTHH:mm:ss').format('DD-MM-YYYY HH:mm')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('master/ao/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('master/ao', fd)
      } else {
        res = await lou.customUrlPost2('master/ao', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.masterRekening = []
      this.detailTagihan = {}
      this.modalInput = {
        no_pembayaran: '',
        description: '',
        va_name: '',
        objekPajak: '',
        masaPajak: '',
        nilaiPajak: '',
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach((element) => {
        var retv = {
          ...element,
          tgl:
            element.tgl === null
              ? ''
              : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(
              alphabeth[cloopni] + alphabeth[cfirst] + '1',
            ).value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value =
                el[element.dataIndex]
            } else {
              worksheet.getCell(
                alphabeth[cloopni] + alphabeth[cfirst] + dincrement,
              ).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master master/ao.xlsx'
      NProgress.done()
      link.click()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    currencyFormatter(params) {
      var ret = params === null ? 'NaN' : lou.curency(params)
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

/* .customInput1 {
  color: orange !important;
}

.customInput1:focus {
  outline: none !important;
  border: 1px solid orange;
  box-shadow: 0 0 10px #719ece;
  color: orange;
}

.customInput1::placeholder {
  color: orange !important;
}

.customInput {
  color: #4e8fd6;
}

.customInput:focus {
  outline: none !important;
  border: 1px solid #4e8fd6;
  box-shadow: 0 0 10px #719ece;
  color: #4e8fd6;
}

.customInput::placeholder {
  color: #4e8fd6 !important;
} */
</style>
